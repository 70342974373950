@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.9.1/font/bootstrap-icons.css");

:root {
  --bs-primary-rgb: 205, 102, 102;
  --bs-primary: #CD6666;
  --bs-link-color: #CBCCCC;
  --bs-link-hover-color: #CD6666;
  --bs-body-color: #313333;
  --bs-body-bg: #606060;
}

.btn-outline-primary {
    --bs-btn-color: #CBCCCC;
    --bs-btn-border-color: #CD6666;
    --bs-btn-hover-border-color: #CBCCCC;
  --bs-btn-hover-bg: #CD6666;
}

a{
  text-decoration: none;
}
